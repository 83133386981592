<template>
  <div class="footer">
    <div class="copyright">
      Copyright © {{ currentYear }}. All rights reserved.
    </div>
    <div class="links">
      <router-link class="links__item" :to="{name: 'privacy', params: {}}">Privacy Policy</router-link>
      <div class="dropdown" v-if="Object.keys(applications).length">
        <span class="dropdown__link">Terms & Conditions</span>
        <div class="dropdown__content">
          <router-link
              class="dropdown__content-item"
              v-for="(app, key) in applications"
              v-show="app.tos"
              :id="key"
              :to="{name: 'tos', params: { id: key }}">{{ app.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import applications from '../../json/applications.json'

export default {
  name: "Footer",
  data: function () {
    return {
      applications,
      currentYear: new Date().getFullYear(),
    }
  }
}
</script>
<style lang="less">
@mobile: 1024px;

.footer {
  width: 970px;
  display: flex;
  justify-content: space-between;
  height: 16px;
  margin-top: 25px;
  margin-bottom: 30px;

  font-family: "San Francisco Pro Text", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #6F7482;

  @media (max-width: @mobile) {
    flex-direction: column;
    width: 100%;
  }

  .copyright {
    all: unset;

    @media (max-width: @mobile) {
      margin-bottom: 15px;
    }
  }

  .links {
    @media (max-width: @mobile) {
      display: flex;
      flex-direction: column;
    }

    .links__item {
      all: unset;
      cursor: pointer;

      @media (max-width: @mobile) {
        margin-bottom: 15px;
      }
    }

    .dropdown {
      z-index: 1;
      margin-left: 45px;
      display: inline-block;
      position: relative;
      cursor: pointer;

      @media (max-width: @mobile) {
        margin-left: 0px;
        margin-bottom: 25px;
      }

      &:hover {
        .dropdown__content {
          display: flex;
        }
      }

      .dropdown__content {
        display: none;
        position: absolute;
        flex-direction: column;
        bottom: 0;
        padding-bottom: 30px;
        width: 100%;

        @media (max-width: @mobile) {
          padding-bottom: 16px;
        }

        .dropdown__content-item {
          all: unset;
          padding: 8px 22px;
          background: #FFFFFF;
          border: 0.3px solid #F8F8F8;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          margin-bottom: 15px;
          text-align: center;

          @media (max-width: @mobile) {
            margin-bottom: 5px;
          }

          &:hover {
            background: #f5f5f5;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>